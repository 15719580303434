:root {
    --primaryColor: hsl(199, 100%, 33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right,  hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyTextr: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --carBG: rgb(225, 225, 235);
}

.header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(14, 237, 237);
    width: 100%;
    padding: 1rem;
    z-index: 1000;
    height: 80px;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.242);

    .logo {
        color: var(--blackColor);
        font-weight: 600;
        cursor: pointer;
        align-items: center;
        justify-content: center;


        h1 {
            font-weight: 900;
        }

        .icon {
            font-size: 25px;
            color: var(--primaryColor);
            transform: translateY(5px);
        }
    }

    @media screen and (max-width: 768px) {
        .navBar {
            position: absolute;
            background: var(--whiteColorDeam);
            height: max-content;
            width: 88%;
            border-radius: 1rem;
            top: -500rem;
            left: 50%;
            padding: 1rem;
            transform: translate(-50%);
            z-index: 2000;
            box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
            transition: .5s ease-in-out;
    
    
    
            .navLists {
                flex-direction: column;
                justify-content: center;
                width: 100%;
                margin: auto;
                padding: 0.5rem 0;
    
                .navItem {
                    padding: 0.5rem 0;
    
                    .navLink {
                        color: var(--textColor);
                        font-size: .9rem;
                        font-weight: 600;
                        &:hover, &:active {
                            color: var(--primaryColor);
                        }
                    }
                }
    
               .btn {
                margin-top: 1rem;
    
                a {
                    font-weight: 600;
                    color: var(--whiteColor);
                  }
               }   
            }
        }

        .closeNavbar {
            position: absolute;
            top: 1rem;
            right: 1.5rem;
            color: var(--primaryColor);
            &:hover, &:active {
                color: var(--secondaryColor);
               }
            }

        .activeNavbar {
            top: 7rem;

        }
    
        .toggleNavbar {
            .icon {
                font-size: 25px;
                color: var(--primaryColor);
            }
            &:hover, &:active {
                color: var(--secondaryColor);
            }
        }
    }
}

// Media Queries =================================>

@media screen and (min-width: 769px) {
    .toggleNavbar, .closeNavbar {
        display: none;
    }

    .header {
        padding: 1.5rem 2rem;

        .navBar {
            .navLists {
                display: flex;
                align-self: center;
                justify-content: center;
                flex-direction: row;

                .navItem {
                    .navLink {
                        color: var(--textColor);
                        padding: 0 0.45rem;
                        font-size: 0.9rem;
                        font-weight: 500;

                        &:hover, &:active {
                            color: var(--primaryColor);
                        }
                    }
                }

                .btn {
                    margin-left: 1rem;

                    a {
                        color: var(--whiteColor);
                        font-weight: 500;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 960) {
    .header {
        .navBar {
            .navLists {
                .navItem {
                    padding: 0 0.7rem;
                }
            }
        }
    }
}