/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #03C4EB;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #03c5ec;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 10px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Welcome
--------------------------------------------------------------*/
#hero {
  display: table;
  width: 100%;
  height: 100vh;
  background: url(../img/textile-home.jpg) top center;
  background-size: cover;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

#hero .hero-logo {
  margin: 20px;
}

#hero .hero-logo img {
  max-width: 100%;
}

#hero .hero-container {
  background: rgba(0, 0, 0, 0.8);
  display: table-cell;
  margin: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

#hero h1 {
  margin: 30px 0 10px 0;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;
  color: #fff;
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
}

#hero h2 {
  color: #ccc;
  margin-bottom: 50px;
}

#hero h2 span {
  color: #fff;
  transition: 0.3s;
  border-bottom: 2px solid #03C4EB;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  #hero h2 .rotating {
    display: block;
  }
}

#hero .rotating>.animated {
  display: inline-block;
}

#hero .actions a {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
}

#hero .btn-get-started {
  background: #03C4EB;
  border: 2px solid #03C4EB;
  color: #fff;
}

#hero .btn-get-started:hover {
  background: none;
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services {
  border: 2px solid #fff;
  color: #fff;
}

#hero .btn-services:hover {
  background: #03C4EB;
  border: 2px solid #03C4EB;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #0d0d0d;
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
}

@media (max-width: 992px) {
  #header {
    height: 60px;
  }
}

#header.fixed-top {
  background: rgba(0, 0, 0, 0.85);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  height: 50px;
}

.scrolled-offset {
  margin-top: 70px;
}

@media (max-width: 992px) {
  .scrolled-offset {
    margin-top: 60px;
  }
}

/*--------------------------------------------------------------
/* Sections Common
--------------------------------*/
section {
  overflow: hidden;
}

.section-title {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.section-description {
  text-align: center;
  margin-bottom: 40px;
}

.section-title-divider {
  width: 50px;
  height: 3px;
  background: #03C4EB;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Get Started Section
--------------------------------*/
#about {
  background: #fff;
  padding: 80px 0;
}

.about-image {
  content: url("../img/about-img.jpg");
}

#about .about-img {
  overflow: hidden;
}

#about .about-img img {
  max-width: 100%;
}

@media (max-width: 768px) {
  #about .about-img {
    height: auto;
  }

  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }
}

#about .about-content {
  background: #fff;
}

#about .about-title {
  color: #333;
  font-weight: 700;
  font-size: 28px;
}

#about .about-text {
  line-height: 26px;
  margin-bottom: 15px;
}

#about .about-text:last-child {
  margin-bottom: 0;
}

/* Services Section
--------------------------------*/
#services {
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("../img/services-bg.jpg") fixed center center;
  background-size: cover;
  padding: 80px 0 60px 0;
}

#services .service-item {
  margin-bottom: 20px;
}

#services .service-icon {
  float: left;
  background: #03C4EB;
  border-radius: 50%;
  transition: 0.5s;
  border: 1px solid #03C4EB;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
}

#services .service-icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

#services .service-item:hover .service-icon {
  background: #fff;
}

#services .service-item:hover .service-icon i {
  color: #03C4EB;
}

#services .service-title {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#services .service-title a {
  color: #111;
}

#services .service-description {
  margin-left: 80px;
  line-height: 24px;
}

/* Testimonials Section
--------------------------------*/
#testimonials {
  background: #f6f6f6;
  padding: 80px 0;
}

.left-quote {
  content: url(../img/quote_sign_left.png);
}

.right-quote {
  content: url(../img/quote_sign_right.png);
}

.cus-1 {
  content: url(../img/client-1.jpg)
}

.cus-2 {
  content: url(../img/client-2.jpg)
}

#testimonials .profile {
  text-align: center;
}

#testimonials .profile .pic {
  border-radius: 50%;
  border: 6px solid #fff;
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
  width: 260px;
}

#testimonials .profile .pic img {
  max-width: 100%;
}

#testimonials .profile h4 {
  font-weight: 700;
  color: #03C4EB;
  margin-bottom: 5px;
}

#testimonials .profile span {
  color: #333333;
}

#testimonials .quote {
  position: relative;
  background: #fff;
  padding: 60px;
  margin-top: 40px;
  font-size: 16px;
  font-style: italic;
  border-radius: 5px;
}

#testimonials .quote b {
  display: inline-block;
  font-size: 22px;
  left: -9px;
  position: relative;
  top: -8px;
}

#testimonials .quote small {
  display: inline-block;
  right: -9px;
  position: relative;
  top: 4px;
}
/* Contact Section
--------------------------------*/
#contact {
  background: #f7f7f7;
  padding: 80px 0 120px 0;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #03C4EB;
  float: left;
  line-height: 0;
}

#contact .info p {
  padding: 0 0 10px 50px;
  line-height: 24px;
}

#contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

#contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

#contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

#contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

#contact .php-email-form input,
#contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
}

#contact .php-email-form input::focus,
#contact .php-email-form textarea::focus {
  background-color: #03C4EB;
}

#contact .php-email-form button[type=submit] {
  background: #03C4EB;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

#contact .php-email-form button[type=submit]:hover {
  background: #25d8fc;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 30px 0;
}

.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: gray;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #111;
  padding: 30px 0;
  color: #fff;
}

#footer .copyright {
  text-align: center;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #ccc;
}