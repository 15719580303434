@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Kaushan+Script&family=Poppins:wght@300;400;500;600;700&family=Red+Hat+Display:ital,wght@0,400;1,700;1,900&family=Roboto:ital@1&display=swap');

/* ===============General=============== */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --primaryColor: hsl(199, 100%, 33%);
    --secondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right,  hsl(187, 85%, 43%), hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(201, 33%, 16%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyTextr: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
    --bodyColor: rgb(240, 240, 246);
    --carBG: rgb(225, 225, 235);
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.section {
    padding: 4rem 0 2rem;
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    align-items: center;
}

.btn {
    padding: .6rem 1.5rem;
    background: var(--gradientColor);
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn a {
    color: white;
    font-weight: 500;
}

.btn:hover {
    background: var(--secondaryColor);
}

img, video {
    width: 100%;
    height: auto;
}

input {
    font-size: 100%;
}

body {
    background: var(--bodyColor);
}
